import { Box, Text, Card, Loader, MarketingPageLayout, MarketingPageLayoutContent, Page, WixDesignSystemProvider, Image, Layout, FeatureList, Button } from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import Popup from './popup.png';
import { ReactTagManager } from 'react-gtm-ts';
import React, { useEffect } from 'react';
import * as Icons from '@wix/wix-ui-icons-common';

const tagManagerArgs = {
  code: 'GTM-WZQPMC7'
}

function App() {
  ReactTagManager.init(tagManagerArgs)
  const token = new URLSearchParams(window.location.search).get('token');
  const instance = new URLSearchParams(window.location.search).get('instance')
  const [isUpgraded, setIsUpgraded] = React.useState(false);

  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: ""
    }
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
  }, [])


  const BASE_URL = `https://certifiedcode.wixsite.com/popup/_functions`

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash
          });
        }
      })
      .catch(() => {

      })
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        "Authorization": instance || ""
      }
    })
      .then(response => response.json())
      .then(data => {
        console.log(data)
        setInstanceData(data);
        setIsUpgraded(data.instance.isFree === false);
      })
      .catch(() => {

      })
  }

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=8b4b2cc1-d378-40d6-9a1a-48ecfa8beebb&redirectUrl=https://certifiedcode.wixsite.com/popup/_functions/@certifiedcode/base-backend/auth`;
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height='100vh'>
        <Page.Content>
          <Box height={"90vh"} direction="vertical" verticalAlign="middle">
            <Loader text="Loading" />
          </Box>
        </Page.Content>
      </Page></WixDesignSystemProvider>
  }

  return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
    <Card>
      <MarketingPageLayout
        removeImageHorizontalPadding
        removeImageVerticalPadding
        content={
          <Box height="840px" verticalAlign="middle">
            <MarketingPageLayoutContent
              title="Reduce business risk with consent popup"
              content="Easily create consent popup to ensure your website is compliant with regulations."
              actions={<Box direction="horizontal" gap="SP1">
                {/* <Button skin="premium" disabled={isUpgraded} prefixIcon={<Icons.PremiumFilled />}
                  onClick={() => {
                    window.open(`https://www.wix.com/apps/upgrade/${"8b4b2cc1-d378-40d6-9a1a-48ecfa8beebb"}?appInstanceId=${(instanceData as any)['instance']['instanceId']}`)
                  }}
                >Upgrade App</Button> */}
                <Button skin='dark' as='a' href='https://support.certifiedcode.us/en/articles/8622373-popup-consent-collector-getting-started' target='_blank'>Setup Guide</Button>
                <Button skin='inverted' as='a' href='https://bridget.reviews.certifiedcode.us/8b4b2cc1-d378-40d6-9a1a-48ecfa8beebb' target='_blank'>Rate Popup 5 stars</Button>
              </Box>}
            />
          </Box>
        }
        image={<Image borderRadius={0} src={Popup} />}
      />
    </Card>
  </WixDesignSystemProvider >
}

export default App;
